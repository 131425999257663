import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import { rhythm } from "../../utils/typography"
import SeriesGrid from "../../components/SeriesGrid"
import Article from "../../components/Article"

const Page = ({ data, location }) => {
  const { edges, totalCount } = data.allMarkdownRemark
  const siteTitle = data.site.siteMetadata.title
  const tagHeader = `${totalCount} post${
    totalCount == 1 ? "" : "s"
  } in Series 'Cuckolder'`

  return (
    <Layout location={location} title={siteTitle}>
      <header style={{ width: "100%", textAlign: "center" }}>
        <h1 style={{ marginBottom: rhythm(2 / 4), marginTop: rhythm(2 / 4) }}>
          {tagHeader}
        </h1>
      </header>

      <SeriesGrid>
        {edges.map(({ node }) => {
          return <Article key={node.fields.slug} node={node} random={false} />
        })}
      </SeriesGrid>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { tags: { in: ["cuckolder"] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            description
          }
        }
      }
    }
  }
`

export default Page
